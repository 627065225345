.review-box{
    margin: 0 0 40px 0;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.review-content img{
    vertical-align: middle;
    border-style: none;
}
.img-fluid{
    max-width:100%;
    height: auto;
}
.pull-right{
    float:right;
}