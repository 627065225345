.syllabus-box{
    margin: 0 0 40px 0;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    padding:20px 28px;
}
.syllabus-content{
    border: 1px solid #dee2e6;

}
.syllabus-content h4{
    background: #a3c9da;
    color:#333;
    padding: 5px 15px;
    font-size: 1.1rem;
    font-weight: 400;
}