
body {
  margin: 0;
  padding-right: 0 !important;
    text-align: left;
    color:#555 !important;
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
