.about-title{
    margin-top: 30px;
    background:none;
   text-align: left;
}
.about-title h2{
    font-size:22px;
    font-weight:700;
    color:#333;
}
.about-title p{
    color:#555;
}
.about-info img{
    width:100%;
}

.about-body{
    color:#333;
    background: #f9f9f9;
}
.about-body li{
    
    text-align: left;
}
.about-card-header{
    font-weight: 700;
    color:#166586;
    cursor: pointer;
}
.what-get{
    background-color: #f9f9f9;
    border: 1px solid #dedfe0;
    padding: 10px 15px;
}
.what-get h2{
    font-size:22px;
    font-weight:800;
    color:#333;
}
.what-get h4,h5{
    color:#333;
}
.what-get h2{
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 10px;
    color:#555;
}
.what-get ul  {
    margin: 0;
    padding:0;
    line-height: 1.5;
    list-style:none;
    display: flex;
    align-items: flex-start;
    justify-content:center;
    flex-wrap: wrap;
}
.what-get li{
    display: flex;
    margin-bottom: 10px;
    width:40%;
    padding-left: 26px;
    font-size: 15px;
    position: relative;
    color:#555;
}
.what-get ul li::before{
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "\f00c";
    color: #166586;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 4px;
}

.about-highlight{
    color:#145d7d;
    font-weight:400;
}

