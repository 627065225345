.modal-img-box{
    position: relative;
    text-align: center;
    color: rgb(233, 18, 18);
    border:none;
}
.modal-img-box img{
    opacity: 0.4;
}
.modal-header .close{
    position: absolute;
    z-index: 1050;
    top: 8px;
    right: 11px;
    color:#166586;
    font-size: 33px;
}


.modal-head-text{
    position: absolute;
    top: 50%;
    left: 37%;
    transform: translate(-30%,-30%);
    font-size: 19px;
    font-weight: 700;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-box{
    background: none;
    color:#166586;
}
.error-box p{
    text-align:center;
    color:red;
    font-size:14px;   
}
.error-box span{
    color:red;
    font-weight: 700;
}
.submitBtn{
    background-color: #166586; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    justify-content:center;

}
.submitBtn:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 20px 0 rgba(0,0,0,0.19);
}
.snackbar{
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #166586; /* background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 10px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1055; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px;


}
.snackbar.show{
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }