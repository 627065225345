.faq-accordion{
    width:100%;
}
.faq-title{
    color:#333;
    font-size:22px;
    font-weight:700;
}
.faq-card-header{
    color:rgb(66,66,66);
    font-size:16px;
    font-weight:600;
    cursor: pointer;
}
.faq-card-body{
    color:#555;
    font-weight:300;
}
