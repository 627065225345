.fixedRight-button {
    position: fixed;
    z-index:999;
    top:45%;
    right:-69px;
    padding:5px 16px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-size:15px;
    color: #fff;
    border-color:#ff2c2c;
    background: #ff2c2c;
    font-weight:600;
    letter-spacing:1px;
  }
  
  .fixedRight-button:hover {
    background:#166586;
    border-color:#fff;
  }
  
  .modal-header .close{
      position: absolute;
      z-index: 1050;
      top: 8px;
      right: 11px;
      color:#166586;
      font-size: 33px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .input-boxB{
      background: none;
      color:#166586;
  }
  .error-boxB p{
      text-align:center;
      color:red;
      font-size:14px;   
  }
  .submitBtn3{
      background-color: #166586; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
      justify-content:center;
  
  }
  .submitBtn3:hover{
      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 20px 0 rgba(0,0,0,0.19);
  }
  .snackBar{
      visibility: hidden; /* Hidden by default. Visible on click */
      min-width: 250px; /* Set a default minimum width */
      margin-left: -125px; /* Divide value of min-width by 2 */
      background-color: #166586; /* Black background color */
      color: #fff; /* White text color */
      text-align: center; /* Centered text */
      border-radius: 10px; /* Rounded borders */
      padding: 14px; /* Padding */
      position: fixed; /* Sit on top of the screen */
      z-index: 1055; /* Add a z-index if needed */
      left: 50%; /* Center the snackbar */
      bottom: 30px;
  
  
  }
  .snackBar.show{
      visibility: visible;
      -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
      animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadeIn {
      from {bottom: 0; opacity: 0;}
      to {bottom: 30px; opacity: 1;}
    }
    
    @keyframes fadeIn {
      from {bottom: 0; opacity: 0;}
      to {bottom: 30px; opacity: 1;}
    }
    
    @-webkit-keyframes fadeOut {
      from {bottom: 30px; opacity: 1;}
      to {bottom: 0; opacity: 0;}
    }
    
    @keyframes fadeOut {
      from {bottom: 30px; opacity: 1;}
      to {bottom: 0; opacity: 0;}
    }