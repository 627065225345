@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap);

body {
  margin: 0;
  padding-right: 0 !important;
    text-align: left;
    color:#555 !important;
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
    background: #fff;
    color:#000;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    z-index:10;
    box-shadow: 0 8px 6px -9px #999;
    position: relative;
    font-family: 'Poppins', sans-serif;
   
  }
  .scroll{
      position: fixed;
      width:100%;
      top:0;
      left:0;
      background:#166586;
      color:#fff;
      transition:all 0.4s;
  }
  .scroll .navmenu li a {
    color:#fff;
  }

  .navbar-logo{
    position: absolute;
  }



.nav-menu {
    display: grid;
    grid-template-columns: repeat(3,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: left;
    width: 70vw;
    justify-content:center;
    margin-right: 2rem;
  } 
 
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }
  .nav-menu li a {
    text-decoration: none;
    color:inherit;
    transition: 0.1s;
}
 
  .nav-links {
    font-weight:600;
   
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #28bdfc;
    color:#fff;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  .contactBar{
    position: absolute;
    left:70%;
    text-align: center;
  }
  .scroll .contactBar{
    display: none;
  }
  .contactBar a{
    text-decoration: none;
    color:inherit;
  }
  .contactBar span{
    color:#166586;
    padding: 0 5px;
    font-size: 17px;
    font-weight:400;
  }
  @media screen and (max-width: 960px) {
  
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #d3d2d2;
      color:#000;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align:left;
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #28bdfc;
      border-radius: 0;
    }
  
   
  
    .menu-icon {
      display: block;
      color:#28bdfc;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.6rem;
      cursor: pointer;
    }
    .contactBar{
      display: none;
    }
  }

.features-col{
    margin:0;
    padding:20px 15px;
    border-radius: 5px;
    
    z-index:-1;
}
.features-col img{
    position: relative;
    vertical-align: middle;
    height:100px;
}
.features-title{
    font-size:13px;
    font-weight: 500;
    color:#555;
    text-align: center;
}
body{
    font-family:'Open Sans','sans serif';
}
.bannerImg{
    margin-top:10vh;
    width:100%;
    height:auto;
}
.banner-title{
    color:#333;
    font-size:27px;
}
.banner-text{
    color:#555;
}
.rating{
    font-weight:500;
}
.badge.badge-box{
    background: #f4c150;
}
.rating>span{
    margin-right: 10px;
    font-size:15px;
}
.badge{
    color: #29303b;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    pointer-events: none;
    border-radius: 3px;
    font-size: 9px;
    padding: 1px 8px;
    font-weight: 700;
    position: relative;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
}
.badge::after{
    border-radius: 3px;
    right: -4px;
    background: inherit;
    content: "";
    height: 11px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 11px;
    z-index: 0;
    display: block;
}
.features-col h4{
    font-size:22px;
    font-weight:700;
    color:#333;
}
.highlight-home{
    color:#145d7d;
    font-weight:400;
}

.magnifyBorder-button {
  padding: 18px 36px;
  border-radius: 61px;
  box-shadow: inset 0 0 0 2px #166586;
  transition: 300ms box-shadow cubic-bezier(0.4, 0, 0.6, 1), 300ms background-color cubic-bezier(0.4, 0, 0.6, 1), 300ms color cubic-bezier(0.4, 0, 0.6, 1);;
  color: #166586;
  font-weight:600;
}

.magnifyBorder-button:hover {
  box-shadow: inset 0 0 0 4px #166586;
  color:#166586;
}

.modal-header .close{
    position: absolute;
    z-index: 1050;
    top: 8px;
    right: 11px;
    color:#166586;
    font-size: 33px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-boxA{
    background: none;
    color:#166586;
}
.error-boxA p{
    text-align:center;
    color:red;
    font-size:14px;   
}
.submitBtn2{
    background-color: #166586; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Safari */
  transition-duration: 0.4s;
    justify-content:center;

}
.submitBtn2:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 20px 0 rgba(0,0,0,0.19);
}
.toast{
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #166586; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 10px; /* Rounded borders */
    padding: 14px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1055; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px;


}
.toast.show{
    visibility: visible;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
  
  @keyframes fadeIn {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadeOut {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
.about-title{
    margin-top: 30px;
    background:none;
   text-align: left;
}
.about-title h2{
    font-size:22px;
    font-weight:700;
    color:#333;
}
.about-title p{
    color:#555;
}
.about-info img{
    width:100%;
}

.about-body{
    color:#333;
    background: #f9f9f9;
}
.about-body li{
    
    text-align: left;
}
.about-card-header{
    font-weight: 700;
    color:#166586;
    cursor: pointer;
}
.what-get{
    background-color: #f9f9f9;
    border: 1px solid #dedfe0;
    padding: 10px 15px;
}
.what-get h2{
    font-size:22px;
    font-weight:800;
    color:#333;
}
.what-get h4,h5{
    color:#333;
}
.what-get h2{
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 10px;
    color:#555;
}
.what-get ul  {
    margin: 0;
    padding:0;
    line-height: 1.5;
    list-style:none;
    display: flex;
    align-items: flex-start;
    justify-content:center;
    flex-wrap: wrap;
}
.what-get li{
    display: flex;
    margin-bottom: 10px;
    width:40%;
    padding-left: 26px;
    font-size: 15px;
    position: relative;
    color:#555;
}
.what-get ul li::before{
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "\f00c";
    color: #166586;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 4px;
}

.about-highlight{
    color:#145d7d;
    font-weight:400;
}


.syllabus-box{
    margin: 0 0 40px 0;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    padding:20px 28px;
}
.syllabus-content{
    border: 1px solid #dee2e6;

}
.syllabus-content h4{
    background: #a3c9da;
    color:#333;
    padding: 5px 15px;
    font-size: 1.1rem;
    font-weight: 400;
}
.review-box{
    margin: 0 0 40px 0;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.review-content img{
    vertical-align: middle;
    border-style: none;
}
.img-fluid{
    max-width:100%;
    height: auto;
}
.pull-right{
    float:right;
}

.nav-tabs .nav-link{
    border: 2px solid #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #166586;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color:#fff;
    font-size:20px;
    font-weight:400;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color:white;
    border-top: 4px solid #166586;
    background-color: #28bdfc;
}

.course-benefit h3{
    font-weight: 500;
    line-height: 1.2;
    color:#333;
}
.course-benefit li{
    color:#555;
}
.course-highlight{
    color:#145d7d;
    font-weight:400;
}
.faq-accordion{
    width:100%;
}
.faq-title{
    color:#333;
    font-size:22px;
    font-weight:700;
}
.faq-card-header{
    color:rgb(66,66,66);
    font-size:16px;
    font-weight:600;
    cursor: pointer;
}
.faq-card-body{
    color:#555;
    font-weight:300;
}

.modal-img-box{
    position: relative;
    text-align: center;
    color: rgb(233, 18, 18);
    border:none;
}
.modal-img-box img{
    opacity: 0.4;
}
.modal-header .close{
    position: absolute;
    z-index: 1050;
    top: 8px;
    right: 11px;
    color:#166586;
    font-size: 33px;
}


.modal-head-text{
    position: absolute;
    top: 50%;
    left: 37%;
    transform: translate(-30%,-30%);
    font-size: 19px;
    font-weight: 700;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-box{
    background: none;
    color:#166586;
}
.error-box p{
    text-align:center;
    color:red;
    font-size:14px;   
}
.error-box span{
    color:red;
    font-weight: 700;
}
.submitBtn{
    background-color: #166586; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Safari */
    transition-duration: 0.4s;
    justify-content:center;

}
.submitBtn:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 20px 0 rgba(0,0,0,0.19);
}
.snackbar{
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #166586; /* background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 10px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1055; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px;


}
.snackbar.show{
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
.fixedRight-button {
    position: fixed;
    z-index:999;
    top:45%;
    right:-69px;
    padding:5px 16px;
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-size:15px;
    color: #fff;
    border-color:#ff2c2c;
    background: #ff2c2c;
    font-weight:600;
    letter-spacing:1px;
  }
  
  .fixedRight-button:hover {
    background:#166586;
    border-color:#fff;
  }
  
  .modal-header .close{
      position: absolute;
      z-index: 1050;
      top: 8px;
      right: 11px;
      color:#166586;
      font-size: 33px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .input-boxB{
      background: none;
      color:#166586;
  }
  .error-boxB p{
      text-align:center;
      color:red;
      font-size:14px;   
  }
  .submitBtn3{
      background-color: #166586; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Safari */
    transition-duration: 0.4s;
      justify-content:center;
  
  }
  .submitBtn3:hover{
      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 20px 0 rgba(0,0,0,0.19);
  }
  .snackBar{
      visibility: hidden; /* Hidden by default. Visible on click */
      min-width: 250px; /* Set a default minimum width */
      margin-left: -125px; /* Divide value of min-width by 2 */
      background-color: #166586; /* Black background color */
      color: #fff; /* White text color */
      text-align: center; /* Centered text */
      border-radius: 10px; /* Rounded borders */
      padding: 14px; /* Padding */
      position: fixed; /* Sit on top of the screen */
      z-index: 1055; /* Add a z-index if needed */
      left: 50%; /* Center the snackbar */
      bottom: 30px;
  
  
  }
  .snackBar.show{
      visibility: visible;
      animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  
  /* Animations to fade the snackbar in and out */
    
    @keyframes fadeIn {
      from {bottom: 0; opacity: 0;}
      to {bottom: 30px; opacity: 1;}
    }
    
    @keyframes fadeOut {
      from {bottom: 30px; opacity: 1;}
      to {bottom: 0; opacity: 0;}
    }
.mainFooter{ 
    width:100%;
 height:40h;
 margin-top:20%;
 background: rgb(36, 35, 35);
 overflow: hidden;
 position: relative;
}
.footer-sub h4{
    color:#28bdfc;
}
.footer-sub h5{
    color:#28bdfc;
}
.pressDownButton {
    background-color: hsl(222, 100%, 95%);
    padding: 12px 24px;
    color:#166586;
    font-weight:600;
    border-radius: 6px;
    border-bottom: 4px solid hsl(221, 89%, 85%);
    border-top: 0px solid rgb(238, 245, 252);
    transition: transform 250ms ease-in-out;
}

.pressDownButton:hover {
    transform: scale(1.10);
    font-weight:800;
}
.pressDownButton:active{
    transform: scale(.9);
}
#footer-sub{
    position:absolute;
    
    width:100%;
    padding:6px 0;
    background:#fff;
    border-bottom: 6px solid #166586;
    border-top:1px solid #166586;
    overflow: hidden;
}

.HomeBtn{
    position: fixed; 
    width: 100%;
    bottom: 20px;
    left: -45%;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    color: #166586;
    opacity: 0.5
}
.HomeBtn:hover{
    opacity: 1;
}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
