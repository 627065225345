.HomeBtn{
    position: fixed; 
    width: 100%;
    bottom: 20px;
    left: -45%;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    color: #166586;
    opacity: 0.5
}
.HomeBtn:hover{
    opacity: 1;
}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }