
.nav-tabs .nav-link{
    border: 2px solid #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #166586;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color:#fff;
    font-size:20px;
    font-weight:400;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color:white;
    border-top: 4px solid #166586;
    background-color: #28bdfc;
}

.course-benefit h3{
    font-weight: 500;
    line-height: 1.2;
    color:#333;
}
.course-benefit li{
    color:#555;
}
.course-highlight{
    color:#145d7d;
    font-weight:400;
}