@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
 
.navbar {
    background: #fff;
    color:#000;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    z-index:10;
    -webkit-box-shadow: 0 8px 6px -9px #999;
    box-shadow: 0 8px 6px -9px #999;
    position: relative;
    font-family: 'Poppins', sans-serif;
   
  }
  .scroll{
      position: fixed;
      width:100%;
      top:0;
      left:0;
      background:#166586;
      color:#fff;
      transition:all 0.4s;
  }
  .scroll .navmenu li a {
    color:#fff;
  }

  .navbar-logo{
    position: absolute;
  }



.nav-menu {
    display: grid;
    grid-template-columns: repeat(3,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: left;
    width: 70vw;
    justify-content:center;
    margin-right: 2rem;
  } 
 
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }
  .nav-menu li a {
    text-decoration: none;
    color:inherit;
    transition: 0.1s;
}
 
  .nav-links {
    font-weight:600;
   
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #28bdfc;
    color:#fff;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  .contactBar{
    position: absolute;
    left:70%;
    text-align: center;
  }
  .scroll .contactBar{
    display: none;
  }
  .contactBar a{
    text-decoration: none;
    color:inherit;
  }
  .contactBar span{
    color:#166586;
    padding: 0 5px;
    font-size: 17px;
    font-weight:400;
  }
  @media screen and (max-width: 960px) {
  
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #d3d2d2;
      color:#000;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align:left;
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #28bdfc;
      border-radius: 0;
    }
  
   
  
    .menu-icon {
      display: block;
      color:#28bdfc;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.6rem;
      cursor: pointer;
    }
    .contactBar{
      display: none;
    }
  }
