@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
body{
    font-family:'Open Sans','sans serif';
}
.bannerImg{
    margin-top:10vh;
    width:100%;
    height:auto;
}
.banner-title{
    color:#333;
    font-size:27px;
}
.banner-text{
    color:#555;
}
.rating{
    font-weight:500;
}
.badge.badge-box{
    background: #f4c150;
}
.rating>span{
    margin-right: 10px;
    font-size:15px;
}
.badge{
    color: #29303b;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    pointer-events: none;
    border-radius: 3px;
    font-size: 9px;
    padding: 1px 8px;
    font-weight: 700;
    position: relative;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
}
.badge::after{
    border-radius: 3px;
    right: -4px;
    background: inherit;
    content: "";
    height: 11px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 11px;
    z-index: 0;
    display: block;
}
.features-col h4{
    font-size:22px;
    font-weight:700;
    color:#333;
}
.highlight-home{
    color:#145d7d;
    font-weight:400;
}
