.features-col{
    margin:0;
    padding:20px 15px;
    border-radius: 5px;
    
    z-index:-1;
}
.features-col img{
    position: relative;
    vertical-align: middle;
    height:100px;
}
.features-title{
    font-size:13px;
    font-weight: 500;
    color:#555;
    text-align: center;
}