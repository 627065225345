.mainFooter{ 
    width:100%;
 height:40h;
 margin-top:20%;
 background: rgb(36, 35, 35);
 overflow: hidden;
 position: relative;
}
.footer-sub h4{
    color:#28bdfc;
}
.footer-sub h5{
    color:#28bdfc;
}
.pressDownButton {
    background-color: hsl(222, 100%, 95%);
    padding: 12px 24px;
    color:#166586;
    font-weight:600;
    border-radius: 6px;
    border-bottom: 4px solid hsl(221, 89%, 85%);
    border-top: 0px solid rgb(238, 245, 252);
    transition: transform 250ms ease-in-out;
}

.pressDownButton:hover {
    transform: scale(1.10);
    font-weight:800;
}
.pressDownButton:active{
    transform: scale(.9);
}
#footer-sub{
    position:absolute;
    
    width:100%;
    padding:6px 0;
    background:#fff;
    border-bottom: 6px solid #166586;
    border-top:1px solid #166586;
    overflow: hidden;
}
